<template>
    <div class="animated fadeIn">
        <CRow>
            <CCol lg="12">
                
                <CButton
                    @click="$router.go(-1)"
                    size="sm"
                    color="warning" 
                > 
                    <font-awesome-icon icon="arrow-left"/> Go back
                </CButton>
            </CCol>
        </CRow>
        <br>
        <CRow>
            <CCol lg="8">
                <CCard>
                    <CCardHeader><font-awesome-icon icon="cog"/>  Manage Document API
                        <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                            <template>
                                <CButton 
                                    size="sm"
                                    color="danger" 
                                    class="float-right" 
                                    @click="toggleEdit(), returnInformation()" 
                                    v-show="edit"
                                >
                                    <font-awesome-icon icon="window-close"/> Cancel
                                </CButton>

                                <CButton 
                                    :disabled="!isValid"
                                    v-if="$store.getters['can']('update-document-template-file-api')"
                                    size="sm"
                                    color="success" 
                                    class="float-right" 
                                    v-show="edit" 
                                    @click="updateFileAPI()"
                                >
                                    <font-awesome-icon icon="save"/> Save
                                </CButton>

                                <CButton 
                                    v-if="$store.getters['can']('edit-document-template-file-api')"
                                    size="sm"
                                    color="info" 
                                    class="float-right" 
                                    @click="toggleEdit" 
                                    v-show="!edit"
                                >
                                    <font-awesome-icon icon="edit"/> Edit
                                </CButton>
                            </template>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol lg="10">
                               <CInput
                                    :lazy="false"
                                    :value.sync="$v.file_api.name.$model"
                                    :isValid="checkIfValid($v.file_api, 'name')"
                                    label="Name" 
                                    type="text" 
                                    placeholder="JO Routing Document API" 
                                    autocomplete="off"  
                                    v-tocapitalize
                                    v-nospecialcharacter
                                    v-model="file_api.name"
                                    :disabled="disable == 1 ? true : false"
                                    invalidFeedback="Must be more than five(5) characters."
                                    
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="10">
                                <label>Method</label>
                                <v-select 
                                    :disabled="disable == 1 ? true : false"
                                    label="name"
                                    :options="methods_type"
                                    v-model="file_api.method"
                                    :class="!disable ? !file_api.method ? 'has-error' : 'has-success' : ''"
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="12">
                               <CInput
                                    :lazy="false"
                                    :value.sync="$v.file_api.api.$model"
                                    :isValid="checkIfValid($v.file_api, 'api')"
                                    label="URL" 
                                    type="text" 
                                    placeholder="https://test.po-routing.com.ph" 
                                    autocomplete="off"  
                                    v-model="file_api.api"
                                    :disabled="disable == 1 ? true : false"
                                    invalidFeedback="Invalid URL"
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="12">
                               <CTextarea
                                    :lazy="false"
                                    :value.sync="$v.file_api.description.$model"
                                    :isValid="checkIfValid($v.file_api, 'description')"
                                    class="grp-text-area"
                                    label="Description"
                                    placeholder="Content..."
                                    autocomplete="off"  
                                    v-tocapitalize
                                    v-model="file_api.description"
                                    :disabled="disable == 1 ? true : false"
                                    invalidFeedback="Must be more than five(5) characters."
                                />
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        
        <CRow>
            <CCol lg="12">
                <CCard>
                    <CCardHeader><font-awesome-icon icon="link"/>  API Parameters
                        <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                    </CCardHeader>
                    <CCardBody>
                        <template v-if="!disable">
                            <CRow>
                                <CCol lg="3">
                                    <label>Name</label>
                                </CCol>
                                <CCol lg="3">
                                    <label>Type</label>
                                </CCol>
                                <CCol lg="2">
                                    <label>Reference number</label>
                                </CCol>
                                <CCol lg="2">
                                    <label>Required</label>
                                </CCol>
                                <CCol lg="2">
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="3">
                                <CInput
                                        type="text" 
                                        placeholder="JO Number" 
                                        autocomplete="off"  
                                        v-model="field.parameter"
                                        :class="!field.parameter ? 'has-inp-error' : 'has-inp-success'"
                                    />
                                </CCol>
                                <CCol lg="3">
                                    <v-select 
                                        label="name"
                                        :options="fields_type"
                                        v-model="field.type"
                                        :class="!field.type ? 'has-error' : 'has-success'"
                                    />
                                </CCol>
                                <CCol lg="2">
                                    <p-check 
                                        :disabled="file_api.document_template_file_api_params.some(function(item) { return item.is_reference})"
                                        class="p-icon p-bigger" 
                                        color="success" 
                                        value="true"
                                        v-model="field.reference_number"
                                    >
                                        <font-awesome-icon class="icon" icon="check"/>
                                    </p-check>  
                                </CCol>
                                <CCol lg="2">
                                    <p-check 
                                        :disabled="field.reference_number ? true : false"
                                        class="p-icon p-bigger" 
                                        color="success" 
                                        value="true"
                                        v-model="field.required"
                                    >
                                        <font-awesome-icon class="icon" icon="check"/>
                                    </p-check>  
                                </CCol>
                                <CCol lg="2">
                                    <CButton 
                                        :disabled="!field.parameter || !field.type"
                                        color="primary" 
                                        @click="addDynamicField()" >
                                        <font-awesome-icon icon="plus"/> Add Field
                                    </CButton>
                                </CCol>
                            </CRow>
                        </template>

                        <CRow>
                            <CCol lg="12">
                                <CDataTable
                                    :items="file_api.document_template_file_api_params"
                                    :fields="parameters_field"
                                    :items-per-page="10"
                                    border
                                    items-per-page-select
                                    sorter
                                    pagination
                                >
                                
                                    <template #parameter="{item}">
                                        <td v-if="!edit">
                                            {{item.parameter}}
                                        </td>
                                        <td v-else>
                                            <CInput
                                                type="text" 
                                                autocomplete="off"  
                                                :value="item.parameter"
                                                v-model="item.parameter"
                                                :class="!item.parameter ? 'has-inp-error' : 'has-inp-success'"
                                            />
                                        </td>
                                    </template>
                                    
                                    <template #type="{item}">
                                        <td v-if="!edit">
                                            {{item.type}}
                                        </td>
                                        <td v-else>
                                            <v-select 
                                                label="name"
                                                :options="fields_type"
                                                v-model="item.type"
                                                :class="!item.type ? 'has-error' : 'has-success'"
                                            />
                                        </td>
                                    </template>

                                    <template #is_reference="{item}">
                                        <td  v-if="!edit">
                                            <CBadge :color="getColorStatus(item.is_reference == '1' ? 'Yes' : 'No')">
                                                {{item.is_reference == '1' ? 'Yes' : 'No'}}
                                            </CBadge>
                                        </td>
                                        <td v-else>
                                            <p-radio 
                                                class="p-icon p-curve p-bigger" 
                                                name="icon_solid" 
                                                color="success"
                                                :value="item.id"
                                                @change="changeReference()"
                                                v-model="selected_reference">
                                                <font-awesome-icon class="icon" icon="check"/>
                                            </p-radio> 
                                        </td>
                                    </template>
                                    <template #is_required="{item}">
                                        <td  v-if="!edit">
                                            <CBadge :color="getColorStatus(item.is_required == '1' ? 'Yes' : 'No')">
                                                {{item.is_required == '1' ? 'Yes' : 'No'}}
                                            </CBadge>
                                        </td>
                                        <td v-else>
                                            <p-check 
                                                :disabled="item.is_reference ? true : false"
                                                class="p-icon p-bigger" 
                                                color="success" 
                                                value="true"
                                                v-model="item.is_required"
                                            >
                                                <font-awesome-icon class="icon" icon="check"/>
                                            </p-check> 
                                        </td>
                                    </template>
                                    <!-- <template #action="{item}"> -->
                                    <template #action="{item, index}">
                                        <td>
                                            <CButton 
                                                @click="removeParameter(item, index)"
                                                color="danger" 
                                                shape="pill" 
                                                size="sm"
                                            >
                                                <font-awesome-icon icon="times"/>
                                            </CButton>
                                        </td>
                                    </template>
                                </CDataTable>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
import { required,minLength, url } from "vuelidate/lib/validators"
export default {
    name: 'DocumentAPIEdit',
    data() {
        return {
            fields_type: [
                {name: "input"},
                {name: "textarea"}
            ],

            
            methods_type: [
                { name: 'GET' },
                { name: 'POST' },
            ],

            parameter_input: [],
            type_input: [],
            reference_number_input: [],
            required_input: [],


            
            field: {
                parameter: null,
                type: null,
                reference_number: false,
                required: false,
            },

            file_api : {
                id: null,
                name: null,
                api: null,
                description: null,
                document_template_file_api_params: []
            },
            
            file_api_backup : {
                id: null,
                name: null,
                api: null,
                description: null,
                document_template_file_api_params: []
            },

            selected_reference: null,
            selected_reference_backup: null,

            recently_removed_param_id: [],

            selected_required_ids: [],

            disable: 1,
            edit: false,

            document_template_file_api_params_backup: null,

        }
    },
    validations: {
        file_api: {
            name: { required, minLength: minLength(5) },
            method: { required, },
            api: { required, url },
            description: { required, minLength: minLength(5) },
            document_template_file_api_params: { required, minLength: minLength(1) }
        },
    },
    computed: {
        isValid () { return !this.$v.file_api.$invalid },
        isDirty () { return this.$v.file_api.$anyDirty },

        parameters_field: function() {
            let custom_field = [
                { key: 'parameter', sorter: false,},
                { key: 'type', sorter: false, },
                { key: 'is_reference', label: 'Reference Number', sorter: false, },
                { key: 'is_required', label: 'Required', sorter: false, },
            ]
            if(!this.disable) {
                custom_field.push({ key: 'action' })
            }
            return custom_field;
        }
    },
    created() {
        this.file_api.id = this.$route.params.id
        this.getFileAPI();
    },
    methods: {
        removeParameter: function (item, index) {
            if(this.file_api.document_template_file_api_params[index].id && this.file_api.document_template_file_api_params[index].created_at) {
                this.recently_removed_param_id.push(item.id)
            }

            let filtered = this.file_api.document_template_file_api_params.filter((each, i) => {
                return i !== index;
            })
            this.file_api.document_template_file_api_params = filtered;
        },
        addDynamicField: function () {
            if(!this.field.parameter || !this.field.type) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Please fill up required fields.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                }) 
            }

            let row = {
                id: Math.floor(Math.random() * 100) + Math.floor(Math.random() * 100) + 1,
                document_template_file_api_id: this.file_api.id,
                parameter: this.field.parameter,
                type: this.field.type.name,
                is_reference: this.field.reference_number,
                is_required: this.field.required,
                recently_added: true,
            }

            for (let i = 0; i < this.file_api.document_template_file_api_params.length; i++) {
                if(this.file_api.document_template_file_api_params[i].parameter == row.parameter && this.file_api.document_template_file_api_params[i].type == row.type) {
                    return this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'error',
                        title: `${row.parameter} already exists.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    }) 
                }
            }

            if(this.file_api.document_template_file_api_params.some(function(item) { return item.is_reference}) && row.is_reference) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: `Multiple reference number is not allowed.`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
            }

            if(row.is_reference) {
                this.selected_reference = row.id
            }

            // Refresh the array //
            const all_params = this.file_api.document_template_file_api_params;
            this.file_api.document_template_file_api_params = []
            all_params.push(row);
            this.file_api.document_template_file_api_params = all_params;
            
            this.field = this.cleaFieldObject();
        },
        cleaFieldObject: function () {
            return {
                parameter: null,
                type: null,
                reference_number: false,
                required: false,
            }
        },
        getFileAPI: function () {
            this.$Progress.start()
            axios.get('/drs/document-template-file-api/' + this.file_api.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.assignData(response.data.data)
                }
            })
        },  
        assignData: function (data) {
            this.selected_required_ids = []
            for (let row of data.document_template_file_api_params){
                row.is_required = row.is_required === "1" ? true : false;
                row.is_reference = row.is_reference === "1" ? true : false;
                if(row.is_reference){
                    this.selected_reference = row.id
                    this.selected_reference_backup = row.id
                } 

                if(row.is_required) {
                    this.selected_required_ids.push(row.id)
                }
            }
            
            this.file_api = data;

            this.file_api_backup = JSON.parse(JSON.stringify(data))
            this.$Progress.finish()
        },
        toggleEdit: function() {
            this.edit = !this.edit
            this.disable = (this.disable + 1) % 2
            if(!this.disable) { 
                this.$v.$touch()
            }
            else {
                this.$v.$reset()
            }
        },
        returnInformation: function () {
            this.file_api = JSON.parse(JSON.stringify(this.file_api_backup))
            this.selected_reference = this.selected_reference_backup
            this.recently_removed_params = []

            
            this.edit_parameters = false;
            this.disable_parameters = 1;
            this.document_template_file_api_params_backup = null
        },
        validateFileAPI:function () {
            if( !this.file_api.name || !this.file_api.method || !this.file_api.api || !this.file_api.description || this.file_api.document_template_file_api_params.length <= 0 ){
                return false;
            } 
            return true;
        },
        changeReference: function () {
            this.file_api.document_template_file_api_params = this.file_api.document_template_file_api_params.map(item=>{
                delete item.is_reference
                return item
            })

            for (let i = 0; i < this.file_api.document_template_file_api_params.length; i++) {
                if(this.file_api.document_template_file_api_params[i].id == this.selected_reference) {
                    this.file_api.document_template_file_api_params[i].is_reference = true
                    this.file_api.document_template_file_api_params[i].is_required = true
                } else {
                    this.file_api.document_template_file_api_params[i].is_reference = false;
                }

                
            }
        },
        updateFileAPI: function () {
            if(JSON.stringify(this.file_api_backup) == JSON.stringify(this.file_api)) {
               this.$swal({
                    icon: "info",
                    title: "Opps!",
                    text: "No changes has been made.",
                })
                return;
            }

            if(this.file_api.document_template_file_api_params.some(function(item) { return !item.parameter || !item.type })) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: `Parameter(s) field cannot be empty.`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
            }

            if(!this.file_api.document_template_file_api_params.some(function(item) { return item.is_reference })) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: `Please select reference number from the parameters.`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
            }

           

            if(this.file_api.document_template_file_api_params.some(function(ele) { return !ele.parameter || !ele.type })) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Parameter(s) Invalid.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                }) 
            }

            if(this.validateFileAPI()){
                this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: "You are trying to update the Document File API.",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: () => { 


                        //Data manipulation
                        for (let i = 0; i < this.file_api.document_template_file_api_params.length; i++) {
                            const element = this.file_api.document_template_file_api_params[i];
                            if(element.type.name) {
                                let selected_type = element.type.name
                                element.type = selected_type
                            }

                            if(element.recently_added) {
                                delete element.recently_added;
                                element.id = null
                            }
                        }

                        let object = this.file_api;
                        if(this.recently_removed_param_id.length > 0) {
                            object.recently_removed_param_id = this.recently_removed_param_id;
                        }

                        if(this.file_api.method.name) {
                            this.file_api.method = this.file_api.method.name
                        }


                        this.$Progress.start()
                        return axios.post('/drs/document-template-file-api/update/' + object.id, object, {validateStatus: () => true})
                        .then(response => {
                            if ( response.status == 200 ) {
                                this.$swal({
                                    icon: "success",
                                    title: "Success!",
                                    text: `${this.file_api.name} has been updated.`,
                                })

                                this.assignData(response.data.data)
                                this.toggleEdit()
                                this.$Progress.finish()

                                this.recently_removed_param_id = []
                            }
                        })
                    }
                }).then((result) => {
                    if(!result.value){
                        this.$swal('Cancelled!','','error');
                    }
                })
                return;
            }
            this.$swal({
                toast: true,
                position: 'top-right',
                timer: 3000,
                icon: 'error',
                title: 'Please fill up required fields.',
                showConfirmButton: false,
                timerProgressBar: true,
            }) 
            return;
        }
    },
    watch: {
        'field.reference_number': function (value) {
            this.field.required = value ? true : false 
        }
    }
}
</script>
